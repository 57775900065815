'use client'

import { CssBaseline, theme, ThemeProvider } from '@hermes/web-components'
import { Work_Sans } from 'next/font/google'
import React from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import 'swiper/css'
import 'swiper/css/pagination'

const workSans = Work_Sans({
  subsets: ['latin']
})

export default function RootLayout({ children }: { children: React.ReactNode }) {
  return (
    <html className={workSans.className}>
      <body>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </body>
    </html>
  )
}
